<script setup>
    import PrimevueToast from 'primevue/toast';

    const viewport = useViewport();

    // Add data layout attribute to body
    useHead({
        bodyAttrs: {
            'data-layout': 'register-seller',
        },
    });

    // Initialize i18n
    const { t: $t } = useI18n();

    const infoList = ref([
        {
            icon: "fa6-solid:barcode",
            iconName: "Barcode",
            title: $t('Upload easily'),
            subtitle: $t('One Excel file enough to start')
        },
        {
            icon: "bi:globe-europe-africa",
            iconName: "Globe terrestre",
            title: $t('Create your offers'),
            subtitle: $t('Create different offers for your different typology of overstocks')
        },
        {
            icon: "fa6-solid:route",
            iconName: "Route",
            title: $t('Auctions'),
            subtitle: $t('Auction your overstock simply')
        },
        {
            icon: "fa6-solid:lock",
            iconName: "Cadena",
            title: 'B2B',
            subtitle: $t('Only accredited buyers can access to your offers')
        },
    ])

</script>
<template>
    <NuxtErrorBoundary>
        <div id="register_seller">
            <!-- Toast -->
            <PrimevueToast position="top-right" group="tr" />

            <aside v-show="viewport.isGreaterOrEquals('lg')">
                <div>
                    <NuxtImg
                        src="https://api.stocklear.com/images/header/logo.svg"
                        alt="Logo Stocklear"
                        height="65px"
                    />
                </div>
                <h1 class="mt-4 px-4 register__seller__aside__title">{{ $t('Sell overstocks in confidence') }}</h1>
                <h2 class="px-4 text-center register__seller__aside__subtitle">{{ $t('We are the B2B marketplace dedicated to overstocks') }}</h2>
                <div class="mt-4 px-4 register__seller__aside__info__list">
                    <div class="register__seller__aside__info__item" v-for="item in infoList">
                        <Icon
                            :name="item.icon"
                            :alt="item.iconName"
                        />
                        <h3 class="register__seller__aside__info__item__title">{{ item.title }}</h3>
                        <p class="register__seller__aside__info__item__subtitle">{{ item.subtitle }}</p>
                    </div>
                </div>
            </aside>
            <main>
                <div class="register__seller__main__card p-4">
                    <slot name="main">
                    </slot>
                </div>
            </main>
        </div>
    </NuxtErrorBoundary>
</template>